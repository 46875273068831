import { BoxProps } from '@mui/material';
import React, { useEffect } from 'react';
import { TransactionResponse } from '@ethersproject/providers';

import { useModalContext } from '../../../hooks/useModal';
import { useWeb3Context } from '../../../libs/hooks/useWeb3Context';
import { useRootStore } from '../../../store/root';
import { getErrorTextFromError, TxAction } from '../../../ui-config/errorMapping';
import { queryClient } from '../../../../pages/_app.page';
import { QueryKeys } from '../../../ui-config/queries';
import { useRequestWithFlashloanProvider } from '../../../hooks/yldr/useRequestWithFlashloanProvider';
import { EControlPositionType } from '../../../types/uniswapTokens';
import { TxPositionActionsWrapper } from '../TxPositionActionsWrapper';

export interface DeleveragePositionActionsProps extends BoxProps {
  marketId?: number;
  positionAddress?: string;
  isWrongNetwork: boolean;
  slippage: number;
  controlType: EControlPositionType;
}

export const DeleveragePositionActions = React.memo(
  ({ marketId, isWrongNetwork, positionAddress, slippage, controlType }: DeleveragePositionActionsProps) => {
    const requestWithFlashloanProvider = useRequestWithFlashloanProvider();
    const { mainTxState, setMainTxState, setTxError, setGasLimit } = useModalContext();
    const { sendTx } = useWeb3Context();

    const [
      deleveragePosition,
      deleverageAlmPosition,
    ] = useRootStore((state) => [
      state.deleveragePosition,
      state.deleverageAlmPosition,
    ]);

    const action = async () => {
      if (!marketId || !positionAddress || mainTxState.success) return;

      try {
        window.gtag('event', 'leverage_modal_deleverage');

        setTxError(undefined);
        setMainTxState({ ...mainTxState, loading: true });

        const deleverageTxData = await requestWithFlashloanProvider({
          fn: controlType === EControlPositionType.AlmPosition ? deleverageAlmPosition : deleveragePosition,
          args: [marketId, positionAddress, slippage],
        });

        const response: TransactionResponse = await sendTx(deleverageTxData);
        await response.wait(1);

        setMainTxState({
          txHash: response.hash,
          loading: false,
          success: true,
        });

        queryClient.invalidateQueries({ queryKey: [QueryKeys.POSITIONS] });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.POSITIONS_DATA] });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.LEVERAGED_POSITIONS] });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.LEVERAGED_POSITIONS_DATA] });

      } catch (error) {
        const parsedError = getErrorTextFromError(error, TxAction.GAS_ESTIMATION, false);
        setTxError(parsedError);
        setMainTxState({
          txHash: undefined,
          loading: false,
        });
      }
    };

    // Update gas estimation
    useEffect(() => {
      if (!marketId || !positionAddress || mainTxState.success) return;

      const calculateGasLimit = async () => {
        const deleverageTxData = await requestWithFlashloanProvider({
          fn: controlType === EControlPositionType.AlmPosition ? deleverageAlmPosition : deleveragePosition,
          args: [marketId, positionAddress, slippage],
        });
        setGasLimit(deleverageTxData.gasLimit?.toString() || '0');
      }

      const timerId = setTimeout(calculateGasLimit, 500);
      const intervalId = setInterval(calculateGasLimit, 15000);
      return () => {
        clearInterval(intervalId);
        clearTimeout(timerId);
      }
    }, [mainTxState.success]);

    return (
      <TxPositionActionsWrapper
        mainTxState={mainTxState}
        isWrongNetwork={isWrongNetwork}
        requiresAmount
        preparingTransactions={false}
        actionText="Deleverage Position"
        actionInProgressText="Deleveraging Position"
        handleAction={action}
      />
    );
  }
);
